
import { defineComponent, PropType, computed } from 'vue';
import useFormData from './useData';
import { httpGet, httpPost } from '@/api';
import DictSelect from '@/components/DictSelect/DictSelect.vue';
import { ajaxLoading, erpConfirm, errorMessage, setTitleLabel, successMessage } from '@/utils/publicMethods';
import FileContent from '@/components/FileContent/FileContent.vue';
type dataType = {
  auditStatus: number;
};
export default defineComponent({
  components: { DictSelect, FileContent },
  props: {
    formType: {
      // 1 新增 2 编辑 3 在编辑 4 审核
      type: Number as PropType<number>,
      default: 1,
    },
    data: {
      type: Object as PropType<dataType>,
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const params: any = {};
    const readOnlyFlag = computed(() => {
      if (props.formType > 3) {
        return true;
      } else {
        return false;
      }
    });
    let {
      rules,
      formData,
      formRef,
      countryList,
      varietyList,
      plantNoList,
      message,
      changeNumber,
      isShochick,
      isPass,
      fileUrlsArray,
      specificationFileArray,
      productFileArray,
      fileType,
      setFormData,
      specificationFileType,
      specificationFileTips,
    } = useFormData(params);
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    //
    const disabled = computed(() => {
      if (formData.nameCn && formData.nameCn !== null) {
        return false;
      } else {
        return true;
      }
    });
    // 大类选择事件
    const querycountry = async (category, isBoolean) => {
      const res = await httpPost('/malicrm/approval/queryCountry', {
        category,
      });
      if (res.code === 200) {
        countryList.value = res.result;
        if (isBoolean) {
          formData.country = null;
          formData.varietyName = null;
          varietyList.value = [];
          formData.plantNo = null;
        }
      } else {
        errorMessage(res.message);
      }
    };
    const passlodery = async () => {
      isShochick.value = false;
      const params = {
        ...formData,
      };
      ajaxLoading.lock();
      const res = await httpPost('/malicrm/label/waitAudit', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        successMessage(message.value);
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    // 新建标签
    const addlabel = async () => {
      const data = { ...formData };
      const data2 = {
        auditStatus: isPass.value,
        id: formData.id,
        version: formData.version,
      };
      const url = props.formType === 3 ? '/malicrm/label/reEditLabel' : props.formType === 4 ? '/malicrm/label/auditLabel' : '/malicrm/label/saveLabel';
      const params = props.formType === 4 ? data2 : data;
      ajaxLoading.lock();
      const res = await httpPost(url, params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        successMessage(message.value);
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    // 二次校验重复
    const checkRepeat = async (q = '') => {
      const res = await httpPost('/malicrm/label/checkLabelName', {
        ...formData,
      });
      if (res.code === 200) {
        if (res.result === 1) {
          if (q) {
            passlodery();
            return;
          }
          addlabel();
        } else {
          erpConfirm('该国家厂号下已存在此中文品名，确定保存重复的中文品名吗？').then(() => {
            if (isShochick.value) {
              passlodery();
              return;
            }
            addlabel();
          });
        }
      } else {
        errorMessage(res.message);
      }
    };
    /** 提交表单 */
    const submitData = async () => {
      console.log('22222222222', formData);
      formRef.value.validate((valid) => {
        if (valid) {
          if (props.formType === 4) {
            message.value = '审核已通过';
          } else {
            message.value = '保存成功';
          }
          if (props.formType !== 4) {
            checkRepeat();
          } else {
            addlabel();
          }
        } else {
          return false;
        }
      });
    };
    // 厂号选择事件
    const queryPlantNo = async (country, isBoolean) => {
      const res = await httpPost('/malicrm/approval/queryPlantNo', {
        category: formData.category,
        country,
      });
      if (res.code === 200) {
        plantNoList.value = res.result;
        if (isBoolean) {
          formData.plantNo = null;
          varietyList.value = [];
          formData.varietyName = null;
        }
      } else {
        errorMessage(res.message);
      }
    };
    // 初始化数据
    const initFile = (resFile, type?) => {
      let resArr = [];
      // 判断文件数量是否大于1，大于1则需要在命名后面添加序号
      let isIndex = resFile && resFile.length > 1;
      if (resFile && resFile.length > 0) {
        resFile.forEach((item, index) => {
          if (type === 2) {
            let params = {
              name: isIndex ? formData.nameCn + '规格说明书' + (index + 1) : formData.nameCn + '规格说明书',
              url: item.url,
            };
            (resArr as any).push(params);
          } else {
            let params = {
              name: isIndex ? formData.nameCn + '产品图片' + (index + 1) : formData.nameCn + '产品图片',
              url: item.url,
            };
            (resArr as any).push(params);
          }
        });
      }
      return resArr;
    };
    const initData = async (id) => {
      const res = await httpGet(`/malicrm/label/queryLabelDetailById/${id}`);
      if (res.code === 200) {
        setFormData(res.result);
        // 获取标签文件并自定义标签名称
        // formData.labelFileName = formData.nameCn + '标签';
        // formData.labelFileUrl = res.result.labelAttachmentDto.url;
        // if (formData.labelFileUrl && formData.labelFileUrl !== '') {
        //   (fileUrlsArray.value as any).push({
        //     name: formData.labelFileName,
        //     url: formData.labelFileUrl,
        //   });
        // }
        if (res.result.labelAttachmentDto && Object.keys(res.result.labelAttachmentDto).length > 0) {
          (fileUrlsArray.value as any).push({
            name: formData.nameCn + '标签',
            url: res.result.labelAttachmentDto.url,
          });
        }
        // 获取规格说明书文件并自定义其名称
        (specificationFileArray.value as any) = initFile(formData.specificationAttachmentDtos, 2);
        // 获取产品图片文件并自定义其名称
        (productFileArray.value as any) = initFile(formData.productPictureAttachmentDtos, 3);
        console.log(productFileArray.value);
      }
    };
    // 搜索品种
    const getSupplierList = async (value) => {
      if (!formData.country || props.formType === 4) {
        return;
      }
      // varietyList.value = [];
      const res = await httpPost('/malicrm/variety/queryByCountry', {
        varietyName: value,
        country: formData.country,
      });
      if (res.code === 200) {
        varietyList.value = res.result;
        if (!value) {
          varietyList.value = varietyList.value.slice(0, 15);
        }
      } else {
        errorMessage(res.message);
      }
    };
    // 是否禁止编辑Fn
    const isDisabledFn = (type: number, arr: Array<number>) => {
      return arr.includes(type);
    };
    // 计算品种占位符
    const varietyNamePlaceHolder = computed(() => {
      if ((props.formType === 2 || props.formType === 4) && !formData.varietyName) {
        return '-';
      } else {
        return '请输入品种';
      }
    });
    // 赋值文件
    const getLabelFile = (backList, type) => {
      let resFiles = [];
      if (backList.length > 1) {
        backList.forEach((v, i) => {
          const params = {
            name: type === 2 ? formData.nameCn + '规格说明书' + (i + 1) : formData.nameCn + '产品图片' + (i + 1),
            url: v.url,
          };
          (resFiles as any).push(params);
        });
      } else {
        backList.forEach((v, i) => {
          const params = {
            name: type === 2 ? formData.nameCn + '规格说明书' : formData.nameCn + '产品图片',
            url: v.url,
          };
          (resFiles as any).push(params);
        });
      }
      return resFiles;
    };
    /** 获取文件信息 */
    const getFileUrls = (fileList: any[]) => {
      // 标签
      if (props.formType === 2) {
        formData.labelFileId = '';
      }
      if (fileList.length > 0) {
        formData.labelFileName = fileList[0].name;
        formData.labelFileUrl = fileList[0].url;
      } else {
        formData.labelFileName = null;
        formData.labelFileUrl = null;
      }
    };
    // 规格说明书
    const getExplainFileUrls = (fileList: any[]) => {
      formData.specificationAttachmentDtos = getLabelFile(fileList, 2);
      console.log('formData.spe', formData.specificationAttachmentDtos);
    };
    // 产品图片
    const getProFileUrls = (fileList: any[]) => {
      formData.productPictureAttachmentDtos = getLabelFile(fileList, 3);
    };
    const auditLabel = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          // 提交审核的是还是需要校验标签文件的，新增编辑不用
          if (!formData.labelFileUrl) {
            errorMessage('标签文件不可为空');
            return;
          }
          isShochick.value = true;
          checkRepeat('q');
        }
      });
    };

    const cancel = () => {
      if (props.formType === 4) {
        isPass.value = 3;
        message.value = '审核不通过';
        addlabel();
      } else {
        close(false);
      }
    };

    // if (props.formType !== 1) {
    //   setFormData(props.data);
    //   if (formData.labelFileUrl && formData.labelFileUrl !== '') {
    //     (fileUrlsArray.value as any).push({
    //       name: formData.labelFileName,
    //       url: formData.labelFileUrl,
    //     });
    //   }
    // }

    if (props.formType === 2 || props.formType === 3 || props.formType === 4) {
      querycountry((props.data as any).category, false);
      queryPlantNo((props.data as any).country, false);
    }
    getSupplierList(formData.varietyName);
    // 审核/编辑/查看详情 获取数据
    if (props.formType > 1) {
      initData((props.data as any).id);
    }
    return {
      initFile,
      disabled,
      close,
      rules,
      formData,
      formRef,
      querycountry,
      queryPlantNo,
      countryList,
      varietyList,
      plantNoList,
      isDisabledFn,
      changeNumber,
      varietyNamePlaceHolder,
      getSupplierList,
      getFileUrls,
      getLabelFile,
      getExplainFileUrls,
      getProFileUrls,
      submitData,
      cancel,
      checkRepeat,
      setTitleLabel,
      auditLabel,
      isShochick,
      message,
      isPass,
      fileUrlsArray,
      specificationFileArray,
      productFileArray,
      fileType,
      specificationFileType,
      specificationFileTips,
      initData,
      readOnlyFlag,
    };
  },
});
