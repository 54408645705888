import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a4a055b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label__form" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  class: "mali-edit__title",
  style: {"margin-top":"20px"}
}
const _hoisted_4 = { class: "label__form-item" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  class: "mali-edit__title",
  style: {"margin-top":"20px"}
}
const _hoisted_7 = { class: "label__form-item" }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = {
  class: "mali-edit__title",
  style: {"margin-top":"20px"}
}
const _hoisted_10 = { class: "label__form-item" }
const _hoisted_11 = {
  key: 3,
  class: "crm-button__group"
}
const _hoisted_12 = {
  key: 4,
  class: "crm-button__group"
}
const _hoisted_13 = {
  key: 5,
  class: "crm-button__group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DictSelect = _resolveComponent("DictSelect")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_FileContent = _resolveComponent("FileContent")!
  const _component_CrmFormLayout = _resolveComponent("CrmFormLayout")!

  return (_openBlock(), _createBlock(_component_CrmFormLayout, {
    title: _ctx.setTitleLabel(_ctx.formType, ['新建品名', '编辑品名', '再编辑品名', '审核标签']),
    onClose: _cache[18] || (_cache[18] = ($event: any) => (_ctx.close()))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.formData,
        "label-position": "top",
        ref: "formRef",
        rules: _ctx.formType === 4 ? {} : _ctx.rules
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "大类",
                      prop: "category"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DictSelect, {
                          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.querycountry($event, true))),
                          modelValue: _ctx.formData.category,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.category) = $event)),
                          name: "goods_category_label",
                          disabled: _ctx.isDisabledFn(_ctx.formType, [2, 3, 4]),
                          placeholder: "请选择大类"
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "国家",
                      prop: "country"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DictSelect, {
                          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.queryPlantNo($event, true))),
                          modelValue: _ctx.formData.country,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.country) = $event)),
                          customOption: _ctx.countryList,
                          disabled: _ctx.isDisabledFn(_ctx.formType, [2, 3, 4]),
                          "key-label": "countryName",
                          "key-value": "country",
                          placeholder: "请选择国家"
                        }, null, 8, ["modelValue", "customOption", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "厂号",
                      prop: "plantNo"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.formData.plantNo,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.plantNo) = $event)),
                          class: "mali-full__input",
                          placeholder: "请选择厂号",
                          disabled: _ctx.isDisabledFn(_ctx.formType, [2, 3, 4]),
                          onChange: _cache[5] || (_cache[5] = 
                  () => {
                    _ctx.getSupplierList();
                  }
                )
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plantNoList, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item,
                                value: item
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "中文品名",
                      prop: "nameCn"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.formData.nameCn,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.nameCn) = $event)),
                          disabled: _ctx.isDisabledFn(_ctx.formType, [4]),
                          clearable: "",
                          maxlength: 50,
                          placeholder: "请输入中文品名"
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "英文品名",
                      prop: "nameEn"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          maxlength: 100,
                          disabled: _ctx.isDisabledFn(_ctx.formType, [4]),
                          modelValue: _ctx.formData.nameEn,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.nameEn) = $event)),
                          clearable: "",
                          placeholder: "请输入英文品名"
                        }, null, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "HS CODE",
                      prop: "hsCode"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          maxlength: 10,
                          modelValue: _ctx.formData.hsCode,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.hsCode) = $event)),
                          disabled: _ctx.isDisabledFn(_ctx.formType, [4]),
                          clearable: "",
                          placeholder: "请输入HS CODE",
                          onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.changeNumber($event)))
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "品种",
                      prop: "varietyName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.formData.varietyName,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formData.varietyName) = $event)),
                          class: "full-input",
                          filterable: "",
                          remote: "",
                          onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.getSupplierList(''))),
                          "filter-method": _ctx.getSupplierList,
                          disabled: _ctx.isDisabledFn(_ctx.formType, [4]),
                          placeholder: _ctx.varietyNamePlaceHolder
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.varietyList, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.varietyName,
                                label: item.varietyName,
                                value: item.varietyName
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "filter-method", "disabled", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      ((_ctx.formType > 3 && _ctx.formData.labelFileUrl && _ctx.formData.labelFileUrl !== '') || _ctx.formType <= 3)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formType > 2 ? '标签文件' : '选择标签文件'), 1),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_row, { gutter: 80 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FileContent, {
                        readOnly: _ctx.readOnlyFlag,
                        disabled: _ctx.disabled,
                        fromType: _ctx.formType == 3 ? 2 : _ctx.formType,
                        fileType: _ctx.fileType,
                        fileList: _ctx.fileUrlsArray,
                        max: 1,
                        size: 5,
                        onSuccess: _ctx.getFileUrls,
                        name: _ctx.formData.nameCn,
                        type: 1
                      }, null, 8, ["readOnly", "disabled", "fromType", "fileType", "fileList", "onSuccess", "name"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      ((_ctx.formType > 3 && _ctx.specificationFileArray && _ctx.specificationFileArray.length > 0) || _ctx.formType <= 3)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formType > 2 ? '规格说明书' : '选择规格说明书'), 1),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_el_row, { gutter: 80 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FileContent, {
                        readOnly: _ctx.readOnlyFlag,
                        fromType: _ctx.formType == 3 ? 2 : _ctx.formType,
                        fileType: _ctx.specificationFileType,
                        fileList: _ctx.specificationFileArray,
                        max: 10,
                        size: 100,
                        onSuccess: _ctx.getExplainFileUrls,
                        disabled: _ctx.disabled,
                        name: _ctx.formData.nameCn,
                        type: 2
                      }, null, 8, ["readOnly", "fromType", "fileType", "fileList", "onSuccess", "disabled", "name"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      ((_ctx.formType >= 3 && _ctx.productFileArray && _ctx.productFileArray.length > 0) || _ctx.formType < 3)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.formType > 2 ? '产品图片' : '选择产品图片'), 1),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_el_row, { gutter: 80 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FileContent, {
                        disabled: _ctx.disabled,
                        readOnly: _ctx.readOnlyFlag,
                        fromType: _ctx.formType,
                        fileType: _ctx.fileType,
                        fileList: _ctx.productFileArray,
                        max: 10,
                        size: 100,
                        onSuccess: _ctx.getProFileUrls,
                        name: _ctx.formData.nameCn,
                        type: 3
                      }, null, 8, ["disabled", "readOnly", "fromType", "fileType", "fileList", "onSuccess", "name"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.formType === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("button", {
              class: "crm-form__button primary",
              onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.submitData && _ctx.submitData(...args)))
            }, "保存"),
            _createElementVNode("button", {
              class: "crm-form__button primary",
              onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.auditLabel && _ctx.auditLabel(...args)))
            }, "提交审核")
          ]))
        : _createCommentVNode("", true),
      (_ctx.formType === 1 || _ctx.formType === 3)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("button", {
              class: "crm-form__button primary",
              onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.submitData && _ctx.submitData(...args)))
            }, "保存"),
            _createElementVNode("button", {
              class: "crm-form__button cancel",
              onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
            }, "取消")
          ]))
        : _createCommentVNode("", true),
      (_ctx.formType === 4)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("button", {
              class: "crm-form__button primary",
              onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.submitData && _ctx.submitData(...args)))
            }, "通过"),
            _createElementVNode("button", {
              class: "crm-form__button cancel",
              onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
            }, "不通过")
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}