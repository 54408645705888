import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import {
  erpConfirm,
  errorMessage,
  getSuffix,
  showFiles,
  successMessage,
} from '@/utils/publicMethods';
import { httpPost } from '@/api';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'labelNo',
      value: '',
      placeholder: '请输入品名编号',
    },
    {
      type: 'signSelect',
      prop: 'category',
      value: '',
      options: 'goods_category_label',
      placeholder: '请选择大类',
    },
    {
      type: 'signSelect',
      prop: 'hasImg',
      value: '',
      options: [
        {
          label: '有',
          value: 1,
        },
        {
          label: '无',
          value: 0,
        },
      ],
      placeholder: '有无标签',
    },
    {
      type: 'input',
      prop: 'country',
      value: '',
      placeholder: '请输入原产国',
    },
    {
      type: 'input',
      prop: 'plantNo',
      value: '',
      placeholder: '请输入厂号',
    },
    {
      type: 'input',
      prop: 'hsCode',
      value: '',
      placeholder: '请输入HS CODE',
    },
    {
      type: 'input',
      prop: 'varietyName',
      value: '',
      placeholder: '请输入品种',
    },
    {
      type: 'input',
      prop: 'nameCn',
      value: '',
      placeholder: '请输入中文品名',
    },
    {
      type: 'input',
      prop: 'nameEn',
      value: '',
      placeholder: '请输入英文品名',
    },
    {
      type: 'multiSelect',
      prop: 'auditStatus',
      value: [],
      options: [
        {
          label: '待审核',
          value: 1,
        },
        {
          label: '已通过',
          value: 2,
        },
        {
          label: '不通过',
          value: 3,
        },
        {
          label: '待完善',
          value: 4,
        },
      ],
      placeholder: '请选择审核状态',
    },
    {
      type: 'signSelect',
      prop: 'isDisable',
      value: '',
      options: [
        {
          label: '未禁用',
          value: false,
        },
        {
          label: '已禁用',
          value: true,
        },
      ],
      placeholder: '请选择禁用状态',
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '品名编号',
      prop: 'labelNo',
      width: 110,
    },
    {
      label: '大类',
      prop: 'category',
      propDesc: 'categoryDesc', // 需要转换的列值的属性名
      width: 80,
    },
    {
      label: '原产国',
      prop: 'countryDesc',
      width: 90,
    },
    {
      label: '厂号',
      prop: 'plantNo',
      minWidth: 100,
    },
    {
      label: '中文品名',
      prop: 'nameCn',
      width: 177,
    },
    {
      label: '英文品名',
      prop: 'nameEn',
      width: 389,
    },
    {
      label: '有无标签',
      prop: 'hasImage',
      component: defineComponent({
        template: `
        <div class="cell-has-image">
          <span class="view" v-if='row.hasImage' @click="viewLabel(row)">查看</span>
          <span class="down" v-if='row.hasImage' @click='downloadLabel(row)'>下载</span>
          <div class="none" v-if='!row.hasImage'>无</div>
        </div>
        `,
        props: ['row'],
        setup() {
          const viewLabel = (row) => {
            showFiles(row.labelFileName, row.labelFileUrl);
          };
          const downloadLabel = (row) => {
            let suffix = '';
            if (row.labelFileName) {
              suffix = '.' + getSuffix(row.labelFileName);
            }
            const labelFileName = `${row.categoryDesc}_${row.countryDesc}_${row.plantNo}_${row.nameCn}${suffix}`;
            showFiles(labelFileName, row.labelFileUrl, false);
          };
          return { viewLabel, downloadLabel };
        },
      }),
      width: 106,
    },
    {
      label: '审核状态',
      prop: 'auditStatusDesc',
      width: 110,
    },
    {
      label: '禁用状态',
      prop: 'isDisableDesc',
      width: 110,
    },
    {
      label: 'HS CODE',
      prop: 'hsCode',
      width: 122,
    },
    {
      label: '品种',
      prop: 'varietyName',
    },
    {
      label: '创建人',
      prop: 'creator',
      width: 120,
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      width: 182,
    },
    {
      label: '操作',
      prop: '',
      component: defineComponent({
        template: `
          <div class='crm-btn-groups' >
             <el-button   v-if="(row.auditStatus ===3 && !row.isDisable && hasPermission('crm:label:save')) || row.auditStatus ===4 && !row.isDisable && hasPermission('crm:label:save')" type='primary' plain @click="clickBtnFn(row,2,'编辑')" >编辑</el-button>

            <el-button   type='warning' plain v-if="row.auditStatus ===1 && !row.isDisable && hasPermission('crm:label:auditLabel')" @click="clickBtnFn(row, 4,'审核')">审核</el-button>

            <el-button  type='primary' plain v-if="row.auditStatus ===2 && !row.isDisable && hasPermission('crm:label:reEditLabel')" @click="clickBtnFn(row, 3,'再编辑')">再编辑</el-button>

            <el-button  type='warning' plain v-if="row.auditStatus ===2 && !row.isDisable && hasPermission('crm:label:disableLabel')" @click="clickBtnFn(row, null, '禁用')">禁用</el-button>

            <el-button  type='warning' plain v-if="row.auditStatus ===2 && row.isDisable && hasPermission('crm:label:disableLabel')" @click="clickBtnFn(row, null, '启用')">启用</el-button>

            <el-button type='info' plain v-if=" row.auditStatus ===4 && !row.isDisable && hasPermission('crm:label:deleteLabel') || (row.auditStatus ===2 && row.isDisable) && hasPermission('crm:label:deleteLabel')" @click="clickBtnFn(row, null, '删除')">删除</el-button>
          </div>        
        `,
        props: ['row'],
        setup() {
          const clickBtnFn = (row, formType, btnName) => {
            // 1 新建 2 编辑 3 再编辑 4 审核
            if (formType) {
              Instance.setupState.rowData.value = row;
              Instance.setupState.setFormType(formType);
              Instance.setupState.showVisible();
            }
            if (btnName === '禁用' || btnName === '启用') {
              let isDisable = true;
              let title = '确定禁用吗？';
              let tips = '已禁用';
              if (btnName === '启用') {
                isDisable = false;
                title = '确定启用吗？';
                tips = '已启用';
              }
              erpConfirm(title).then(async () => {
                const params = {
                  id: row.id,
                  isDisable,
                  version: row.version,
                };
                const res = await httpPost(
                  '/malicrm/label/disableLabel',
                  params
                );
                if (res.code === 200) {
                  successMessage(tips);
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              });
            }
            if (btnName === '删除') {
              erpConfirm('确定删除吗？').then(async () => {
                const params = {
                  id: row.id,
                  version: row.version,
                };
                const res = await httpPost(
                  '/malicrm/label/deleteLabel',
                  params
                );
                if (res.code === 200) {
                  successMessage('已删除');
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              });
            }
          };
          return { clickBtnFn };
        },
      }),
      fixed: 'right',
      width: 205,
    },
  ];
  return { tableRef, filterOptions, columnList };
};
