
import CrmList from '@/components/CrmList/CrmList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import { defineComponent, getCurrentInstance, ref } from 'vue';
import { ajaxLoading } from '@/utils/publicMethods';
import LabelForm from './components/LabelForm.vue';
import router from '@/router';
export default defineComponent({
  name: 'LabelList',
  components: { CrmList, LabelForm },
  setup() {
    const instance = getCurrentInstance() as any;
    const { filterOptions, columnList, tableRef } = useListData(instance);
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    const { visible, showVisible, setFormType, formType } = useVisible();
    const rowData = ref({});
    const closeVisible = (status) => {
      visible.value = false;
      // 解除重复提交
      ajaxLoading.unLock();
      if (status) {
        refreshTable(status);
      }
    };
    const add = () => {
      setFormType(1);
      showVisible();
    };
    const getLoadPage = (url) => {
      router.push(url);
    };
    return {
      add,
      filterOptions,
      columnList,
      tableRef,
      visible,
      showVisible,
      closeVisible,
      refreshTable,
      rowData,
      setFormType,
      formType,
      getLoadPage,
    };
  },
});
