import { reactive, ref, toRefs } from 'vue';
export default (params = {}) => {
  const formRef = ref();
  type LabelFormType = {
    category?: string;
    [propName: string]: any;
  };
  type FileType = {
    name: string;
    url: string;
  };
  const formData: LabelFormType = reactive({
    category: '',
    country: null,
    varietyName: null,
    hsCode: null,
    id: null,
    labelFileId: null,
    labelFileName: null,
    labelFileUrl: null,
    productPictureAttachmentDtos: [],
    specificationAttachmentDtos: [],
    nameCn: null,
    nameEn: null,
    plantNo: null,
    version: null,
    ...params,
  });
  const rules = reactive({
    category: [{ required: true, message: '请选择大类', trigger: 'change' }],
    country: [{ required: true, message: '请选择国家', trigger: 'blur' }],
    varietyName: [{ required: true, message: '请输入品种', trigger: 'blur' }],
    plantNo: [{ required: true, message: '请选择厂号', trigger: 'blur' }],
    nameCn: [{ required: true, message: '请输入中文品名', trigger: 'blur' }],
    nameEn: [{ required: true, message: '请输入英文品名', trigger: 'blur' }],
    hsCode: [{ required: true, message: '请输入HS CODE', trigger: 'blur' }],
  });
  const customFormData = reactive({
    countryList: [],
    varietyList: [],
    plantNoList: [],
    fileUrlsArray: [], // 框架合同文件
    specificationFileArray: [],
    productFileArray: [],
    fileType: 'basicLabelImg',
    fileTips: '允许类型：jpg, png, gif',
    specificationFileType: 'specification',
    specificationFileTips: '允许类型：pdf.word.excel.jpg.png ',
    isPass: 2,
    message: '保存成功',
    isShochick: false,
  });
  // blur事件
  const changeNumber = (e) => {
    const value = e.target.value;
    formData.hsCode = value.replace(/[^0-9]/g, ''); // 清除数字以外的字符
  };
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };

  return { formRef, formData, rules, ...toRefs(customFormData), changeNumber, setFormData };
};
